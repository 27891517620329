<template>
    <b-overlay :show="loading">
        <b-row class="justify-content-center">
            <b-col sm="12" md="11">
                <form-tanggal @submit="submit"></form-tanggal>
                <b-card v-if="journals">
                    <div class="d-flex justify-content-end mb-2">
                        <b-button @click.prevent="print" variant="outline-primary">
                            Download
                        </b-button>
                    </div>
                    <b-table small :fields="fields" :items="journals" responsive>
                        <template #cell(order)="{index}">
                            {{ ++index }}
                        </template>
                        <template #cell(tanggal)="{ item }">
              {{humanDate(item.tanggal)}}
            </template>
                        <template #cell(nomor)="{item}">
                            <span v-if="item.akun">
                                {{ item.akun.nomor }}
                            </span>
                            <i class="text-danger" v-else>
                                Nomor akun tidak ditemukan
                            </i>
                        </template>
                        <template #cell(akun)="{item}">
                            <span v-if="item.akun">
                                {{ item.akun.nama }}
                            </span>
                            <i class="text-danger" v-else>
                                Akun tidak ditemukan
                            </i>
                        </template>
                        <template #cell(debit)="{item}">
                            <strong>Rp {{ formatRupiah(item.debit) }}</strong>
                        </template>
                        <template #cell(kredit)="{item}">
                            <strong>Rp {{ formatRupiah(item.kredit) }}</strong>
                        </template>
                        <template #cell(total)="{item}">
                            <strong>Rp {{ formatRupiah(item.debit - item.kredit) }}</strong>
                        </template>
                    </b-table>
                </b-card>
                <section v-else class="alert alert-danger p-1">
                    Harap pilih tanggal awal dan tanggal akhir untuk melihat hasil
                </section>
            </b-col>
        </b-row>
    </b-overlay>
</template>
<script>
import {BTable, BOverlay, BRow, BCol, BCard, BButton, BFormGroup, BFormInput} from 'bootstrap-vue'
import FormTanggal from './components/FormTanggal'
export default {
    components: {
        FormTanggal,
        BTable, BOverlay, BRow, BCol, BCard, BButton, BFormGroup, BFormInput
    },
    computed: {
        journals() {
            return this.$store.state.laporan.journals
        }
    },
    data: () => ({
        loading: false,
        form: {
            tgl_awal: null,
            tgl_akhir: null
        },
        fields: [
            { key: 'order', label: '#' },
            { key: 'tanggal', label: 'Tanggal' },
            { key: 'nomor', label: 'Nomor' },
            { key: 'akun', label: 'Akun' },
            { key: 'debit', label: 'Debit' },
            { key: 'kredit', label: 'Kredit' },
            { key: 'total', label: 'Total' }
        ]
    }),
    methods: {
        async print() {
            if(!this.journals) {
                this.displayError({
                    message: 'Harap klik lihat laporan terlebih dahulu sebelum download!'
                })
                return false
            }

            const params = Object.assign({}, this.form)
            params.url = this.$route.meta.api

            try {
                this.loading = true
                const response = await this.$store.dispatch('laporan/getData', params)
                window.open(URL.createObjectURL(response))
                this.loading = false
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        async submit({tgl_awal, tgl_akhir}) {
            try {
                this.loading = true
                this.form.tgl_awal = tgl_awal
                this.form.tgl_akhir = tgl_akhir
                await this.$store.dispatch('laporan/getJournal', this.form)
                this.loading = false
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        }
    }
}
</script>